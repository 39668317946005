import Vue from 'vue'
import router from './router'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
import echarts from 'echarts'
import axios from 'axios'
import QS from 'qs';
import VueCookies from 'vue-cookies';
import Base64  from 'js-base64';
import globalVariable from "@/api/global_variable";
import './assets/style.css'
import BaiduMap from 'vue-baidu-map'

Vue.config.productionTip = false

Vue.prototype.$echarts = echarts
Vue.prototype.$axios = axios;
Vue.prototype.qs = QS;
Vue.prototype.GLOBAL = globalVariable;
Vue.use(VueCookies);
Vue.prototype.$md5 = Base64;
Vue.config.productionTip = false

Vue.use(VueDirectiveImagePreviewer, {
  zIndex: 9999,  //层级显示
  previewSize: 3 //三倍图
})
Vue.use(ElementUI, {
  size: 'medium'
})
Vue.use(BaiduMap, {
  //百度地图
  ak: 'rk73w8dv1rkE4UdZsataG68VarhYQzrx'
})
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')