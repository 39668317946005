import Vue from 'vue'
import VueRouter from 'vue-router'

//解决vue-router报NavigationDuplicated: Avoided redundant navigation to current location 的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
const routes = [
	{
		path: '/',
		name: 'index',
		component: () =>
			import( /* webpackChunkName: "about" */ '../index.vue'),
		meta: {
			title: "搜广助手 - 广告服务 统计分析 数据分析"
		}
	},{
	path: '/search',
	name: 'search',
	component: () =>
		import( /* webpackChunkName: "about" */ '../search.vue'),
   },{
		path: '/tree',
		name: 'tree',
		component: () =>
			import( /* webpackChunkName: "about" */ '../views/tree.vue'),
	},{
		path: '/map',
		name: 'map',
		component: () =>
			import( /* webpackChunkName: "about" */ '../views/map.vue'),
	}
]

const router = new VueRouter({
	//mode: 'history',
	hash: true,
	routes
})
export default router